.content-div-pi-duplicate {
    background: white;
    width: 94%;
    margin: auto;
    min-height: 300px;
    max-height: 350px;
    border: 1px dotted;
    border-radius: 9px;
    padding: 15px 10px;
    position: relative;
}
#duplicate-div{
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    color: red;
    font-size: 18px;
    font-weight: 600;
    transition: 0.3s;
    transform: scale(0);
    font-family: math;
}
.col-sm-4.Duplicate-PI-button {
    DISPLAY: FLEX;
    ALIGN-ITEMS: end;
}
.col-sm-4.Duplicate-PI-button button {
    color: white;
    background: #00335b;
}

.please-select-pi {
    TEXT-TRANSFORM: uppercase;
    LETTER-SPACING: 1PX;
    FONT-WEIGHT: 700;
    FONT-FAMILY: system-ui;
}