.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.user-selection-container {
  padding-left: 14px;
}

#UserSecurityItemGrid {
  padding-left: 14px;
}