.duplicate div {
    color: #ff0000ad;
}

.duplicate #_dialog-header #_title {
    color: red;
}
.Duplicate-Content{
    
        color: #ff0000d1 ;
        font-weight: 500;
    
}