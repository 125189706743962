.access-denied {	
	height: 100%;
	margin-left: 500px;
	color: red;
}

.message{
	margin-left: 10px;
}

.ban-icon {
	margin-top:25px;
	margin-left: 250px;
	font-size:48px;
	color:red
}


