.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.accessory-add-container{

  background-color: white;
}

#AccessoryInformationGrid {
  padding-left: 14px;
}

.heading {
  margin: 0;
  text-align: center;
}
#CurrencyGrid_dialogEdit_wrapper{
  min-width: 50vw !important;
  min-height: 60vh !important;
  // z-index: 758957;
}
div:has(#CurrencyGrid_dialogEdit_wrapper){
  z-index: 9834448 !important;
  // min-height: 60vh !important;
  // max-width: 60vw !important;
  // display: flex !important;
  // justify-content: center;
  // align-items: center;
  // padding-right: 0px !important;
}

.e-dlg-container{
  padding-right: 0px !important;
  
}
#CurrencyGrid_dialogEdit_wrapper_dialog-content{
  scroll-behavior: none;
}
#CurrencyGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat{

  visibility:hidden;
}

div#CurrencyGrid_dialogEdit_wrapper_title {
  display: none;
}