.common_colour {
    color: #d79928;
}
.company {
	color: white;
}

.submit_btn {
	background-color:#09325b !important;
	border-radius:28px;
	border:1px solid #09325b !important;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #09325b !important;
}
.submit_btn:hover {
	background-color:#09325b !important;
}
.submit_btn:active {
	position:relative;
	top:1px;
}
.button_container {
	width: 100%;
	text-align: center;
}
.footer {
	position: fixed;
	right: 0;
	bottom: 0;
}

.link {
	cursor: pointer;
}