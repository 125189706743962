.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.customer-add-container{
  min-height: 100vh;
  background-color: white;
}

#CustomerInformationGrid {
  padding-left: 14px;
}
mat-card {
  text-align: left;
}
.heading {
  margin: 0;
  // text-align: center;
}
#CustomerGrid_dialogEdit_wrapper{
  min-width: 90vw !important;
  min-height: 90vh  !important;
  max-width: 90vw !important;
  max-height: 90vh !important;
  // z-index: 758957;
}
div:has(#CustomerGrid_dialogEdit_wrapper){
  z-index: 9834448 !important;
  // min-height: 60vh !important;
  // max-width: 60vw !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-right: 0px !important;
}

.e-dlg-container{
  padding-right: 0px !important;
  
}
#CustomerGrid_dialogEdit_wrapper_dialog-content{
  scroll-behavior: none;
}
#CustomerGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat{

   visibility:hidden;
 }

div#CustomerGrid_dialogEdit_wrapper_title {
  display: none;
}


.tip-content {
  text-transform: uppercase;
  background-color: #fcdbe4;
  border-color: #fcdbe4;
  font-family: Arial, Helvetica, sans-serif;
  color: #f44336;
  max-width: 360px;
  min-width: 30px;
  padding: 0;
  text-align: center;
  font-weight: 700;
  visibility: visible;
  padding: 12px;
  word-break: break-word;
  // z-index: 888;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
}
.tip-content::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 5px 10px 5px; /* Change size as needed */
  border-style: solid;
  background-color: #fcdbe4;
  border-color: transparent transparent #fcdbe4 transparent; /* Change color as needed */
  // filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
}
#check-contact-nos{
  display: flex;
  justify-content: center;
  padding-top: 10px;
  z-index: 8;

  position: absolute;
  width: 100% !important;
}

.col-sm-6.replicate-customer-div {
  display: flex;
  align-items: end;
  // padding-bottom: 10px;
}

button.replicate-customer {
  border: none;
  padding: 10px;
  color: #09325b;
  filter: drop-shadow(2px 4px 6px black);
  background: white;
  font-weight: 700;
  letter-spacing: 1px;
}

.replicate-customer-div {
  position: absolute;
  top: 90%;
  right: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-left: auto;
  gap: 20px;
  align-items: end;
}

.replicate-customer-div:last-child{
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  box-shadow: none;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: #00335b;
}


button.cancel-replicate:hover{
  color:white ;
  background: #00335b;
}
button.cancel-replicate {
  border: none;
  padding: 10px 15px;
  font-size: 15px;
  letter-spacing: 2px;
  color: #00335b;
  background: white;
  text-transform: uppercase;
}