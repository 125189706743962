.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.accessory-add-container{
  min-height: 100vh;
  background-color: white;
}

#AccessoryInformationGrid {
  padding-left: 14px;
}

.heading {
  margin: 0;
  text-align: center;
}
#messageGrid_dialogEdit_wrapper{
  min-width: 90vw !important;
  min-height: 90vh  !important;
  max-width: 90vw !important;
  max-height: 90vh !important;
  // z-index: 758957;
}
div:has(#messageGrid_dialogEdit_wrapper){
  z-index: 9834448 !important;
  // min-height: 60vh !important;
  // max-width: 60vw !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-right: 0px !important;
}

.e-dlg-container{
  padding-right: 0px !important;
  
}
#messageGrid_dialogEdit_wrapper_dialog-content{
  scroll-behavior: none;
}
#messageGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat{

  visibility:hidden;
}

div#messageGrid_dialogEdit_wrapper_title {
  display: none;
}


// .pi {
//   padding-bottom: 10px;
//   margin: auto;
//   width: 300px;
// }