.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  
  .loader div {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #3498db;
    animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .loader div:nth-child(1) {
    animation-delay: -0.32s;
  }
  
  .loader div:nth-child(2) {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: scale(0.0);
    }
    50% {
      transform: scale(1.0);
    }
  }
  

  #messageGrid > .e-gridcontent > .e-content{
    max-height: 300px !important;
  }
  h3.heading-dashboard-massage {
    margin: 0px;
}
h3.heading-dashboard-massage {
  padding: 10px;
  background: white;
}
.col-sm-6.dashboard-massage {
  background: white;
}

// @media screen and (max-width: 960px) {
//   .makeStyles-image-2 {
//     background-size: 70% 70% !important;
//   }
//   .makeStyles-image-2{
//     max-width: 41% !important;
//   }
// }


// .dashboard-pi-view DIV {
//   DISPLAY: FLEX;
//   JUSTIFY-CONTENT: CENTER;
//   ALIGN-ITEMS: BASELINE;
//   GAP: 10PX;
// }