/* EmailComposeComponent.css */
.email-editor{
    padding: 20px;
}
.email-compose-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    
  }
  
  .form-section {
    margin-bottom: 15px;
  }
  
  .label {
    font-weight: bold;
  }
  
  .email-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .editor-section {
    margin-bottom: 20px;
  }
  
  .send-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }
  
  /* Style for CC and BCC sections */
  .cc-bcc-section {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  .cc-bcc-button {
    margin-right: 10px;
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
  }
  
  .cc-bcc-input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: none; /* Hide initially */
  }
  
  .show-input .cc-bcc-input {
    display: block; /* Show when class is applied */
  }
  


  .pi-div {
    padding-bottom: 10px;
    margin: auto ;
    // width: 300px;
  }
  .col-sm-4.pi-div {
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 15px;
}

.col-sm-4.pi-div button {
  border: none;

  font-weight: 500;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 5px;
  color:#Fff;
  
  background-color: #00335b;

}
.col-sm-4.pi-div button:hover {
  color: #00335b;
  background: #fff;
  
}
.col-sm-4.pi-div >  div {
  width: 180px;
}
.col-sm-4.pi-div >  div > div{
  width: 180px;
}