table {
    // width: 50%;
    border-collapse: collapse;
}

.ths, td {
    // border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.ths {
    text-align: right;
    font-size: smaller;
}
tr.tr-view-pi {
    border: 1.5px solid #6c757d;
}

tr.tr-view-pi:hover {
    background-color:  #6c757d;
}
// tr.tr-view-pi:nth-child(2),
// tr.tr-view-pi:nth-child(4),
// tr.tr-view-pi:nth-child(6)
// tr.tr-view-pi:nth-child(8),
// tr.tr-view-pi:nth-child(10),
tr.tr-view-pi:nth-child(even)
 {
    background-color:  #D3D3D3;

}

// .e-control.e-dialog.e-lib.e-dlg-modal.e-popup.e-popup-open {
//     min-width: 95%;
// }

h4.view-pi-messages {
    text-align: left;
    padding: 2px;
}