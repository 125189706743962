.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.accessory-add-container{
  min-height: 100vh;
  background-color: white;
}

#AccessoryInformationGrid {
  padding-left: 14px;
}

.heading {
  margin: 0;
  text-align: center;
}
#CountryCityPortGrid_dialogEdit_wrapper{
  min-width: 90vw !important;
  min-height: 90vh  !important;
  max-width: 90vw !important;
  max-height: 90vh !important;
  // z-index: 758957;
}
div:has(#CountryCityPortGrid_dialogEdit_wrapper){
  z-index: 9834448 !important;
  // min-height: 60vh !important;
  // max-width: 60vw !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-right: 0px !important;
}

.e-dlg-container{
  padding-right: 0px !important;
  
}
#CountryCityPortGrid_dialogEdit_wrapper_dialog-content{
  scroll-behavior: none;
}
#CountryCityPortGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat{

  visibility:hidden;
}

div#CountryCityPortGrid_dialogEdit_wrapper_title {
  display: none;
}

/* CSS for highlighting filtered columns */
.e-grid .e-filtered::before{
  color: red;
  font-weight: 800;
}
.filtered-column {
  background-color: #ffeed2; /* Set your preferred background color */
  font-weight: bold;
  color: red;
}
div#SerialNo-xlfldlg,
div#CityName-xlfldlg,
div#PortName-xlfldlg,
div#CountryName-xlfldlg {
  min-width: 600px !important;
  max-width: 600px !important;
  max-height: 550px !important;
  z-index: 9999999 !important;
  /* left: 66px; */
}
.e-ddl.e-input-group.e-control-wrapper .e-input {
  font-size: 16px;
  font-family: emoji;
  color: #00335b;
  background: white;
}
