.upload-file-main-divs {
    width: 500px;
    margin: auto;
    height: 100px;
    background: rgba(129, 148, 170, 0.1803921569);
    border: 1px dashed #00335b;
    border-radius: 5%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
input#excelFile{
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    /* z-index: 10; */
}
.file-link {
    color: #475f7b;
    text-decoration: underline;
    margin-left: 3px;
}
.kb-modal-data-title {
    width: 500px;
    margin: auto;
    margin-bottom: 0px;
}
.upload-excel-heading {
    padding: 10px;
    font-size: 20px;
    padding-bottom: 0px;
}
.upload-supplier-wrappers {
    padding-top: 0px;
}

.file-atc-boxs {
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
    transition: box-shadow 0.15s linear;
   
padding: 5px;
border-radius: 0.25rem;
box-shadow: none;


}
.file-details {
    flex: 0 ,70%;
    // width: calc(100% - 210px);
}
.file-details h6 {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    padding: 4px;
    // line-height: 10px;
    // margin-bottom: 8px;
}
.file-details p {
    font-size: 12px;
    color: #8194aa;
    line-height: initial;
    font-weight: 400;
    margin-bottom: 8px;
}
.file-actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}

.file-action-btn {
    font-size: 12px;
    color: #8194aa;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-right: 15px;
    cursor: pointer;
}
.file-action-btn:hover {
    color: #3d546f;
    text-decoration: underline;
}
.file-images {
    /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
    width: 50px !important;
    height: 50px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    // background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px;
}
.file-images img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
}

.kb-buttons-box {
    width: 500px;
    margin: auto;
    padding: 10px;
 
}
img#loader {
    width: 25px;
    height: 25px;
    margin-left: 12px;
    display: none;
}
p#successMessage {
    padding: 5px;
    color: green;
    display: none;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.kb-buttons-box {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.download-delete-toolbar{
    margin: 5px 20px;
    margin-top: 20px;
    display: flex;
    gap: 20px;
    
}

.btn-delete-all:hover{
    transition: color .15s ease-in-out,background-color .15s ease-in-out,text-shadow .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: white;
    color: red;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Shadow with blur */
}
.btn-delete-all{
    background-color: red;
    padding: 5px 10px;
    color: white;
    text-transform: uppercase;
    border: none;
    border-color: red;
    border-radius: .25rem;
    cursor: pointer;

}
.btn-download-all:hover{
    transition: color .15s ease-in-out,background-color .15s ease-in-out,text-shadow .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: white;
    color: #32552b ;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Shadow with blur */
}
.btn-download-all{
    background-color: #32552b ;
    padding: 5px 10px;
    color: white;
    text-transform: uppercase;
    border: none;
    border-color: #32552b ;

    border-radius: .25rem;
    cursor: pointer;
}


.file-atc-boxs:hover{
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}

p.large-file-title {
    color: red;
    font-weight: bold;
    letter-spacing: 0.9px;
    font-size: 1rem;
}
ul.large-file-list {
    list-style: circle;
    color: #002f51;
    line-height: 2;
    font-size: 12px;
}