.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.employee-add-container{
  min-height: 100vh;
  background-color: white;
}

#EmployeeInformationGrid {
  padding-left: 14px;
}

.heading {
  margin: 0;
  text-align: center;
}
#EmployeeGrid_dialogEdit_wrapper{
  min-width: 90vw !important;
  min-height: 90vh  !important;
  max-width: 90vw !important;
  max-height: 90vh !important;
  z-index: 758957;
}
div:has(#EmployeeGrid_dialogEdit_wrapper){
  z-index: 98344480 !important;
  // min-height: 60vh !important;
  // max-width: 60vw !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-right: 0px !important;
}

.e-dlg-container{
  padding-right: 0px !important;
  
}
#EmployeeGrid_dialogEdit_wrapper_dialog-content{
  scroll-behavior: none;
}
#EmployeeGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat{

  visibility:hidden;
}

div#EmployeeGrid_dialogEdit_wrapper_title {
  display: none;
}

.tip-content-div {
  text-transform: uppercase;
  background-color: #fcdbe4;
  border-color: #fcdbe4;
  font-family: Arial, Helvetica, sans-serif;
  color: #f44336;
  max-width: 360px;
  min-width: 30px;
  padding: 0;
  text-align: center;
  font-weight: 700;
  visibility: visible;
  padding: 12px;
  word-break: break-word;
  // z-index: 888;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
}
.tip-content-div::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 5px 10px 5px; /* Change size as needed */
  border-style: solid;
  background-color: #fcdbe4;
  border-color: transparent transparent #fcdbe4 transparent; /* Change color as needed */
  // filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
}
#check-contact-noss {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  // z-index: 8;
  position: absolute;
  width: 100% !important;
  top: -30px;
}

.gird-div {
  position: relative;
  
}

#duplicate-value-detected{
  opacity: 0;
  transition: 2s;
}