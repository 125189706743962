.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.accessory-add-container{
  min-height: 100vh;
  background-color: white;
}

#AccessoryInformationGrid {
  padding-left: 14px;
}

.heading {
  margin: 0;
  text-align: center;
}
div#ProformaInvoiceGrid_dialogEdit_wrapper{
  min-width: 90vw !important;
  min-height: 90vh  !important;
  max-width: 90vw !important;
  max-height: 90vh !important;
  // z-index: 758957;
}
div:has(#ProformaInvoiceGrid_dialogEdit_wrapper){
  z-index: 9834448 !important;
  // min-height: 60vh !important;
  // max-width: 60vw !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-right: 0px !important;
}


.e-dlg-container{
  padding-right: 0px !important;
  
}
#ProductFinishSizeGrid_dialogEdit_wrapper_dialog-content{
  scroll-behavior: none;
}
#ProformaInvoiceGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat{

  visibility:hidden;
}

div#ProductFinishSizeGrid_dialogEdit_wrapper_title {
  display: none;
}


input#ConfirmConsigneeName,
#CommissionAmountPerUnit,
input#AbsoluteCommissionAmount,
#BookAmount ,
#PrimaryCommissionPercentOnAdjustment,
#SecondaryCommissionPercentOnAdjustment,
#AdjustmentAmount,
#BasePriceCompany,
#BasePriceAgent,
input#ProformaInvoiceGridReceiptAmount
{
  text-align: right;
  padding-right: 4px;
}

#ApproveCommissionAmountPerUnit
  #ApproveCommissionAmountPerUnit {
    display: flex;
    justify-content: center;
    align-items: end;
    margin-top: 16px;

  // padding-bottom: -6px;
}
div#ApproveCommissionAmountPerUnit,
#ApproveAbsoluteCommissionAmount, 
  #ConfirmConsigneeName,
  #ApproveSecondaryCommissionPercentOnAdjustment,
  #ApprovePrimaryCommissionPercentOnAdjustment
  {
  display: flex;
  align-items: end;
  justify-content: center;
  margin-top: 16px;
}

// checkbox



.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  // margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 30%;
  left: 40%;
  height: 15px;
  width: 15px;
  // padding: 5px;
  background-color: #fff;
  border: 1px solid #09325b;
}
label.checkbox-container {
  width: 100%;
}

.checkbox:checked ~ .checkmark {
  background-color:#09325b;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox:checked ~ .checkmark:after {
  display: block;
}

.checkbox:checked ~ .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// ///////////////////////////////////////////////////
/* EmailComposeComponent.css */
.email-editor{
  padding: 20px;
}
.email-compose-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  
}

.form-section {
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
}

.email-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.editor-section {
  margin-bottom: 20px;
}

.send-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background-color: #0056b3;
}

/* Style for CC and BCC sections */
.cc-bcc-section {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.cc-bcc-button {
  margin-right: 10px;
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.cc-bcc-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: none; /* Hide initially */
}

.show-input .cc-bcc-input {
  display: block; /* Show when class is applied */
}
.attech-file-content input {
    width: 100%;
    height: 100%;
    z-index: 35 !important;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
}
//email section
.email-footer {
  display: flex;
  justify-content: space-between;
  padding: 2px 50px;
  align-items: flex-start;
}

.attech-file-content {
  width: 100px;
  height: 40px;
  position: relative;
  margin: auto;
}
.attech-file-content button {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
   top: 0px; 
   left: 0px;
  cursor: pointer;
  background: #09325b;
  color: white;
  border: none;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  letter-spacing: 1px;
}
.send-email{
  cursor: pointer;
  background: #09325b;
  color: white;
  border: none;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  letter-spacing: 1px;
  padding: 10px;
}
ul.atteched-file {
  list-style: none;
  padding: 0px;
}
ul.atteched-file  li {
  padding: 10px;
  background: #09325b;
  color: white;
  font-weight: 600;
  margin-top: 10px;
  position: relative;
}

ul.atteched-file  li .plus {
  transform: rotateZ(46deg);
  font-weight: 500;
  color: red;
    /* padding: 5px; */
    font-size: 30px;
}
ul.atteched-file  li span img {

    width: 30px;
    margin-top: 5px;
    margin-left: 5px;
    color: red !important;

}
ul.atteched-file  li span {
  position: absolute;
  top: 0px;
  left: 101%;

 
  cursor: pointer;
}
.not-supported-file {
  position: absolute;
  top: 14px;
  left: 50px;
  /* background: red; */
  color: red;
  z-index: 4555;
}
span.jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_variant_initial.jodit-toolbar-button_file.jodit-ui-group__file {
  display: none;
}

.Cc-span{
  cursor: pointer;
}
.Cc-span:hover{
  color: #000;
}
.Bcc-span{
  cursor: pointer;
}
.Bcc-span:hover{
  color: #000;
}
.cc-bcc-spans{
  color: #09325b;
}
.from-email {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* EmailMultiSelect.css */
.custom-multi-select .item-checkbox {
  display: none; /* Hide checkboxes */
}

/* EmailMultiSelect.css */
.custom-multi-select .multi-select__option:focus {
  background-color: #09325b !important;
  color: white !important;
}

.custom-multi-select .multi-select__input:checked + .multi-select__label {
  background-color: gray !important;
  color: white !important;
}

/* EmailMultiSelect.css */
.custom-multi-select .multi-select__option--focus {
  background-color: #09325b !important;
  color: white !important;
}

.custom-multi-select .multi-select__option--selected {
  background-color: gray !important;
  color: white !important;
}

.css-wsp0cs-MultiValueGeneric {
  color: #09325b !important;
}
.css-t3ipsp-control:hover {
  border-color: #09325b !important;
}
.css-t3ipsp-control:focus {
  border-color: #09325b !important;
}
.css-t3ipsp-control:active{
  border-color: #09325b !important;
}
.css-13cymwt-control {
  border-color: #09325b !important;
}
.custom-select .Select__menu {
  left: 0;
}

.custom-select .Select__option {
  text-align: left !important;
  color: red !important;
}
.css-1nmdiq5-menu
 {
  color: #09325b !important;
  text-align: left;
}
.css-b62m3t-container:hover {

  border-color: #09325b !important;
}
.css-b62m3t-container{
  border-color: #09325b !important;
}
.css-1nmdiq5-menu:hover ~ .css-13cymwt-control{
  border-color: #09325b !important;
}

span.e-input-group.e-control-wrapper.e-valid-input {
  border-radius: 5px;
  min-height: 38px;
  border-color: darkseagreen;
}

input#textbox_18 {
  margin-top: auto;
  margin-bottom: auto;
}

input#textbox_17 {
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}
span.e-input-group.e-control-wrapper.e-disabled.e-valid-input {
  min-height: 38px;
}
.jodit-wysiwyg{
  min-height: 200px !important;
}
a.jodit-status-bar-link {
  display: none;
}

.jodit-wysiwyg > p {
  text-align: left ;
}
div#ApproveCommissionAmountPerUnit, #ApproveAbsoluteCommissionAmount, #ConfirmConsigneeName, #ApproveSecondaryCommissionPercentOnAdjustment, #ApprovePrimaryCommissionPercentOnAdjustment {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 21px !important;
}

#ProformaInvoiceDetailGrid .e-headertext{
  font-size: 12px; /* Adjust the font size as per your requirement */
}

// .e-menu-item:has(.e-menu-icon .e-icons .e-excl-filter-icon .e-filtered){
//   display: none;
// }

// for display none clear filter from list filter dialog start
// div.e-contextmenu-wrapper  ul li.e-disabled.e-menu-item{
//   display: none !important; 
// }

// .e-contextmenu-wrapper > ul li:nth-last-child(2){
//   display: none !important;
// }
h5#error-1, #error-2 {
  font-size: medium;
  margin: 0px;
  padding: 0px;
  color: red;
  text-align: center;
  padding-bottom: 5px;
  display: none;
}
// for display none clear filter from list filter dialog end

h4#Account-amt {
  color: #09325b;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
}

tr.e-row:has(div.pendingPayment) {
  // background: rgba(255, 0, 0, 0.836) !important;
  background: #69FF47 !important;
}
// tr.e-row.e-altrow td:has(div.pendingPayment) {
//   color: white !important;
// }
p.showSuccess {
    position: absolute;
    top: calc(45% - 50px);
    left: calc(45% - 50px);
    background: white;
    padding: 25px;
    font-size: large;
    color: #00335b;
    font-weight: 600;
    filter: drop-shadow(2px 4px 6px black);
    z-index: 9999;
    padding-bottom: 2px;
    // position: relative;
}
// p.showSuccess p {
//   position: relative;
//   width: 100%;
//   height: 100%;
// }
p.showSuccess p button {
  // position: absolute;
  // bottom: 5px;
  // right: 5px;
  color: #000;
  background-color: #00335b;
  filter:  drop-shadow(2px 4px 6px #eee);
}

p.showSuccess p button:hover {
  color: #00335b;
  background-color: #fff;
}
p.showSuccess p button {
  color: #fff;
  background-color: #00335b;
  filter: drop-shadow(1px 3px 3px #eee);
  margin-top: 15px;
  padding: 5px 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
  outline: none;
  border: none;
  text-transform: uppercase;
}
.scroll-th{
  background-color: #fff;
  border-color: #eaeaea;
  min-height: 120px !important;
}
.div-scroll{
  width: 100%;
  overflow-x: auto;
}


.scroll-th {
  background-color: #fff;
  border-color: #eaeaea;
  min-height: 120px !important;
  /* width: 151px; */
  border-right: 1px solid #eee;
  text-transform: uppercase;
  font-size: 15px;
  padding: 15px;
}


p.showSuccess {
  position: absolute;
  top: calc(40% - 50px);
  left: calc(35% - 50px);
  background: white;
  padding: 25px;
  font-size: medium;
  color: #00335b;
  font-weight: 600;
  filter: drop-shadow(2px 4px 6px black);
  z-index: 9999;
  padding-bottom: 2px;
}

p.showSuccess p button {
  color: #fff;
  background-color: #00335b;
  filter: drop-shadow(1px 3px 3px #eee);
  margin-top: 15px;
  padding: 5px 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
  outline: none;
  border: none;
  text-transform: uppercase;
  FONT-SIZE: 15PX;
}
// p.showSuccess::before{
//   content: "";
//   position: absolute;
//   width: 100vw;
//   height: 100vh;
//   background-color: red;
//   top: 0px;
//   left: 0px;
// }

.loader-div {
  min-height: 400px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}
.loader-div img {
  // filter: drop-shadow(2px 4px 0px black);
  width: 60px;
}
#ProformaInvoiceDetailGrid .e-gridcontent > .e-content{
  height: auto !important;
}

p.lists-headings {
  margin: 0px;
  padding: 15px 20px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.e-email {
  font-family: 'Font Awesome 5 Free'; /* If using FontAwesome icons */
  font-weight: 900; /* Adjust font weight as needed */
  font-size: 16px; /* Adjust the size as needed */
  color: #2196F3; /* Adjust the color as needed */
}

// .message-icon::before{
// content: '++';

// }

// tr.e-row:has(div.pendingPayment ){
//   .e-row:hover{
//   background: #69FF47 !important;
// }
select#CustomerName_hidden {
    text-transform: initial !important;
}
#CustomerName {
  text-transform: initial !important;
}
#CustomerName_popup ul li {
  text-transform: initial !important;
}
/* Define CSS rule to target the table when the checkbox is checked */
input#check-checkbox:checked  + .e-grid .e-table {
  width: auto !important;
  color: #09325b !important;
}

p.pi-balance-amount {
  margin: 0px;
  color: #002f51;
  font-weight: 600;
}

p.lists-headings {
  padding-bottom: 0px;
}