* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: #fff;
  color: #09325b;
  padding: 0;
  height: 100vh;
}

.theme-options {
  max-width: 800px;
  margin: 0 auto;
  text-align: right;
  padding:20px 0;
  div {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin:2px 10px;
    border-radius: 1px solid #fff;
    display: inline-block;
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
  }
}

#theme-white {
  background-color: #eee;
}
#theme-blue {
  background-color: #1e41c1;
}
#theme-orange {
  background-color: #b64b1e;
}
#theme-purple {
  background-color: #7852ae;
}
#theme-green {
  background-color: #5faf3b;
}
#theme-black {
  background-color: #000;
}

.App.theme-white {
  background-color: rgb(241, 87, 87);
  color: #222;
  border-radius: 8px;  
  h3{
    // line-height: 1.2;
    // margin: 0;
    // font-size: 26px;
  }
  h5{
    // line-height: 1.5;
    // margin:0 0 22px 0;
    // font-size: 20px;
  }
}

.App.theme-blue {
  background-color: #1e41c1;
  .content-box {
    background-color: #385cdf;
    h3,
    h5 {
      color: #1e41c1;
    }
    p {
      color: #000;
    }
  }
}

.App.theme-orange {
  background-color: #f4c9b7;
  .content-box {
    background-color: #f7ae8e;
    h3,
    h5 {
      color: #b64b1e;
    }
    p {
      color: #000;
    }
    
  }
  // .e-grid .e-groupdroparea {
  //   background-color: #458aeb;
  //   border-top-color: #052149;
  //   color: #8b919e;
  // }
}

.App.theme-purple {
  background-color: #7852ae;
  .content-box {
    background-color: #b2a6c4;
    h3,
    h5 {
      color: #b64b1e;
    }
    p {
      color: #000;
    }
  }
}

.App.theme-green {
  background-color: #5faf3b;
  .content-box {
    background-color: #aec7a3;
    h3,
    h5 {
      color: #b64b1e;
    }
    p {
      color: #000;
    }
  }
}

.App.theme-black {
  background-color: #000;
  .content-box {
    background-color: rgb(15, 7, 7);
    h3,
    h5 {
      color: #b64b1e;
    }
    p {
      color: #000;
    }
  }
}