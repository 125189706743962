.yarn-contract-container {
  margin-left: 100px;

  .sale-purchase {
    margin: 8px;
    min-width: 150px;
  }
}

.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.godown-add {
  float: right;
}

.users-container {
  max-height: 66vh;
  max-width: 58vw;
}
div:has(#usersGrid_dialogEdit_wrapper){
  z-index: 9999999999  !important;
}

.checkbox-container {
  padding-top: 25px;
}

#usersGrid {
  padding-left: 14px;
}
p#error {
  font-size: 10px;
  color: red;
  text-align: left;
  padding-left: 3px;
  padding-top: 3px;
}.errorSpan {
  font-size: 12px;
  color: red;
  padding-left: 5px;
  margin-top: 5px;
}

.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input, .e-float-input input, .e-float-input.e-control-wrapper input, .e-input-group textarea.e-input, .e-input-group.e-control-wrapper textarea.e-input, .e-float-input textarea, .e-float-input.e-control-wrapper textarea, .e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled input.e-input, .e-input-group.e-control-wrapper.e-disabled input.e-input, .e-input-group.e-disabled textarea.e-input, .e-input-group.e-control-wrapper.e-disabled textarea.e-input {
  background: none;
  // text-transform: lowercase;
  font-family: Arial, Helvetica, sans-serif;
  color: inherit;
}