@import './assets/syncfusion/ej2-base/styles/fabric.css';
@import './assets/syncfusion/ej2-buttons/styles/fabric.css';
@import './assets/syncfusion/ej2-calendars/styles/fabric.css';
@import './assets/syncfusion/ej2-dropdowns/styles/fabric.css';
@import './assets/syncfusion/ej2-inputs/styles/fabric.css';
@import './assets/syncfusion/ej2-navigations/styles/fabric.css';
@import './assets/syncfusion/ej2-popups/styles/fabric.css';
@import './assets/syncfusion/ej2-splitbuttons/styles/fabric.css';
@import './assets/syncfusion/ej2-react-grids/styles/fabric.css';

.App {
  text-align: center;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0.5em;
  font-weight: bold;
}

// .e-grid .e-headercelldiv {
//   overflow-wrap: break-word;
//   word-wrap: break-word;
//   // div{display:table-caption;}
//   // e-headertext{display:table-caption;}
//   word-spacing: 9999rem;
// }

[class*=sidebar-light-] .sidebar a {
  color: #e1ac53;
  text-transform: uppercase;
}

[class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link {
  // color: #000;
  color: #e1ac53;
  // width: 250%;
}

.brand-link {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: 0.8125rem 0.5rem;
    transition: width .3s ease-in-out;
    white-space: nowrap;
    text-align: center;
}

.sidebar-dark-success .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-success .nav-sidebar>.nav-item>.nav-link.active {
  background-color: #e1ac53;
  
  color: #09325b;
  font-weight: bold;
}

.nav-link {
    display: block;
    padding: 0.5rem 0rem 0.5rem 0rem;
    padding-top: 0.5rem;
    padding-right: 0rem;
    padding-bottom: 0.5rem;
    padding-left: 0.3rem;
}

[class*=sidebar-light] .list-group-item .search-path {
  color: #062e52;
  font-size: 15px;
}

// Sidebar Color
[class*=sidebar-light-] {
    // background-color: #fff;
    background-color: #09325b;
    // color: #000;
    color: #e1ac53;
    width: 258px;

}

.text-light {
  color: #00335b!important;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #c8c8c8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li.nav-item {
  margin-top: 6px;
}


.os-theme-light>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
  // background: #8a8c90;
  background: #e1ac53;
}

.os-scrollbar-auto-hidden{
   opacity: 1 !important; 
   visibility: visible !important;
  // pointer-events: none;
}
.os-scrollbar-auto-hidden:hover{
   opacity: 1 !important; 
   visibility: visible !important;
  // pointer-events: none;
}
.os-scrollbar .os-scrollbar-vertical .os-scrollbar-auto-hidden{
  opacity: 1 !important; 
   visibility: visible !important;
}
.os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:hover{
  // background: #8a8c90;
  background: #e1ac53;
}
// .content-wrapper {
//   position: relative;
// }

iframe{
  z-index: 0 !important;
}

[class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link:focus, [class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link:hover {
  // background-color: rgba(54, 51, 236, 0.1);
  // color: #e1ac53;
  color: #09325b;
  background-color: #e1ad53c0;
}

h3 {
  color: #09325b;
 
}
.e-grid.e-gridhover tr[role=row]:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-rtl .e-gridhover tr[role=row]:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell){
  color: #e1ac53;
}
.e-grid .e-rowcell:not(.e-editedbatchcell), .e-grid .e-detailrowcollapse:not(.e-editedbatchcell), .e-grid .e-detailrowexpand:not(.e-editedbatchcell), .e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell), .e-grid .e-gridheader .e-rowdragdrop:not(.e-editedbatchcell), .e-grid .e-emptyrow:not(.e-editedbatchcell){
  color: #09325b;
  
}
.e-grid .e-headercelldiv{
  color: #09325b;

}
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text{
  color: #FFf;
}

.e-toolbar .e-tbar-btn {
  // background: ;
   color: #fff;
}
.e-grid .e-icons{
  color:#09325b;
}
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text:hover{
  color:#e1ac53;
}
.e-toolbar .e-tbar-btn:hover{
  color:#e1ac53;

}

.e-input-group:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-input-group.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
  border-color: #e1ac53;
}
.e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #e1ac53;
}
.e-input-group:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-input-group.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
  border-color: #e1ac53;
}
.e-input-group .e-search {
  border-color: #e1ac53;
}
.e-grid .e-groupdroparea {
  background-color: #09325b;
  border-top-color: #fff;
  color: #e1ac53;
  }
  .e-pager{
    color: #09325b;
  }
  .e-ddl.e-input-group.e-control-wrapper .e-input {
     color: #09325b;
 }

  .e-ddl.e-input-group.e-control-wrapper .e-input {
     color: #09325b !important;
 
}
.e-input-group:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-input-group.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]){
  border-color: #062e52 !important;
}
.e-pager .e-currentitem, .e-pager .e-currentitem:hover {
   color: #062e52;
 
}
.main-footer {
  border-top: 1px solid #062e52;
  color: #09325b;
 }

 .navbar-light .navbar-nav .nav-link {
  color: #062e52;
}
.e-checkbox-wrapper .e-label, .e-css.e-checkbox-wrapper .e-label {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: #062e52;
}

.e-checkbox-wrapper .e-label, .e-css.e-checkbox-wrapper .e-label {
 
  color: #062e52;
}
.e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: #09325b;
  border-color: #062e52;
  color: #fff;
}

.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
  background-color: #062E51;
  border-color: #062e52;
  color: #e1ac53;
}
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: #062e52;
}

.e-grid .e-groupdroparea.e-grouped {
  background-color: #09325b;
}

.e-grid .e-groupcaption, .e-grid .e-indentcell, .e-grid .e-recordplusexpand, .e-grid .e-recordpluscollapse {
 
  color: #09325b;
}

.e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover {
  background-color: #e1ac53;
  border-color: #e1ac53;
  
}

.e-btn.e-flat.e-primary:active, .e-btn.e-flat.e-primary.e-active, .e-css.e-btn.e-flat.e-primary:active, .e-css.e-btn.e-flat.e-primary.e-active {
  background-color: #09325b;
  border-color: #09325b;

}

iframe{
  display: none;
}

.makeStyles-avatar-4 {
  margin: 8px;
  background-color: #09325b !important;
}

.makeStyles-submit-6 {
  border-color: #d79928 !important;
  border-radius: 25px;
  background-color: #09325b !important;
}

.MuiFormLabel-root.Mui-focused{
  color: #09325b !important;
}

.left-100 {
  left: -100px !important;
  right: auto !important;
}

// .e-gridcontent {
// max-width: 1200px;

// }
.e-gridcontent > .e-content{
  overflow-y: auto !important;
  height: 300px !important;
 
}
.e-grid .e-groupdroparea {

  font-size: 15px  !important;
  font-weight: lighter;
  opacity: 1;
  padding: 10px 0 8px !important;
  text-align: center;
  text-indent: 1pt;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.e-grid .e-groupdroparea {
  height: auto;
  min-height: 45px !important;
}

input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-family: "Segoe UI", "GeezaPro", "DejaVu Serif", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 12px !important;
  font-weight: 400;
}

.e-toolbar .e-tbar-btn .e-icons.e-btn-icon {
  font-size: 13px !important;
  vertical-align: middle;
}

.e-toolbar .e-tbar-btn .e-tbar-btn-text {
  display: inline-block;
  font-family: "Segoe UI", "GeezaPro", "DejaVu Serif";
  font-size: 12px !important;
  line-height: inherit;
  vertical-align: middle;
  width: auto;
}

.e-grid .e-headercelldiv {
  font-size: 12px !important;
  }

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv, .e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
  // padding-right: 10px  !important;
}

.e-grid.e-wrap .e-rowcell, .e-grid.e-wrap .e-columnheader .e-stackedheadercelldiv, .e-grid.e-wrap .e-columnheader .e-headercelldiv {
  // line-height: 1px !important;
 }

 .e-grid th
{
  padding: 2px !important;
}

.e-grid .e-headercelldiv {
  padding: 1px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
}

.e-grid td {
  padding: 4px !important;
}


.e-grid * {
  font-size: 10px !important;
}

select#FromCustomerName_hidden {
    text-transform: initial !important;
}
#FromCustomerName {
  text-transform: initial !important;
}
#FromCustomerName_popup ul li {
  text-transform: initial !important;
}

select#ToCustomerName_hidden {
    text-transform: initial !important;
}
#ToCustomerName {
  text-transform: initial !important;
}
#ToCustomerName_popup ul li {
  text-transform: initial !important;
}
select#CustomerName_hidden {
    text-transform: initial !important;
}
#CustomerName {
  text-transform: initial !important;
}
#CustomerName_popup ul li {
  text-transform: initial !important;
}
.content-wrapper {
  padding-left: 10PX;
}